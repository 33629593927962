* {
  font-size: 14px;
}
body {
  background-image: url("./img/bubble-handphone.png");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
  overflow-x: hidden;
}
.c-btn {
  background-color: #5356fb !important;
  color: white !important;
  border-radius: 15px !important;
}
.card {
  background-color: #faf2ff81 !important;
  backdrop-filter: blur(5px) !important;
  -webkit-backdrop-filter: blur(5px) !important;
  border: 1px solid transparent !important;
  border-radius: 10px !important;
}

/* react day picker */
.rdp {
  --rdp-cell-size: 30px !important;
  --rdp-accent-color: #0000ff !important;
  --rdp-background-color: #5356fb !important;
  /* Switch to dark colors for dark themes */
  --rdp-accent-color-dark: #3003e1 !important;
  --rdp-background-color-dark: #180270 !important;
  /* Outline border for focused elements */
  --rdp-outline: 2px solid var(--rdp-accent-color) !important;
  /* Outline border for focused and selected elements */
  --rdp-outline-selected: 2px solid rgba(0, 0, 0, 0.75) !important;
  margin: 0 !important;
  display: flex !important;
  justify-content: center !important;
}
.rdp-cell {
  padding: 3px !important;
}
.my-selected {
  color: white !important;
  background-color: #5356fb !important;
}

/* ant date panel */
.ant-picker-panels {
  display: flex !important;
  flex-direction: column !important;
}
.ant-picker-panel-container {
  margin-top: -18% !important;
  margin-left: -10% !important;
  transform: scale(0.8) !important;
}

/* halaman riwayat */
.table > tbody > tr.table-active > td {
  background: pink;
}
.table > tbody > tr.tr-custom:hover > td {
  transform: scale(1.1);
  background: #d0d2ff;
  cursor: pointer;
}

/* kode ini untuk handle runtime error ketika interceptor unauthorized */
body > iframe {
  display: none;
}

/* responsive */
@media (min-width: 400px) {
  /* react day picker */
  .rdp-cell {
    padding: 10px !important;
  }
}

@media (min-width: 768px) {
  body {
    background-image: url("./img/bubble-tablet.png");
  }

  /* react day picker */
  .rdp-cell {
    padding: 3px !important;
  }

  /* ant date panel */
  .ant-picker-panel-container {
    margin-top: 0 !important;
    margin-left: 0 !important;
    transform: scale(1) !important;
  }
}

@media (min-width: 950px) {
  /* ant date panel */
  .ant-picker-panels {
    flex-direction: row !important;
  }
}

@media (min-width: 992px) {
  body {
    background-image: url("./img/bubble-laptop.png");
  }
}

@media (min-width: 1200px) {
  /* react day picker */
  .rdp-cell {
    padding: 5px !important;
  }
}

@media (min-width: 1400px) {
  /* react day picker */
  .rdp-cell {
    padding: 7px !important;
  }
}
/* responsive */
